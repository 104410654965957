@import "../../styles/colors.scss";

.wrapper {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0a0a0a;

  &_subtitle {
    color: white;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 58px;

    &_pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 464px) {
        gap: 5px;
      }

      &_button {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #b1976c;
        cursor: pointer;
        border: none;
        transition: 0.2s;

        &:hover {
          background-color: darken(#b1976c, 10%);
        }
      }

      &_label {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 46px;
        width: 50px;

        @media (max-width: 464px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  &_item {
    position: relative;
    background-color: black;
    color: white;
    cursor: pointer;
    // overflow: hidden;

    &_img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      display: block;
      width: 100%;
      height: 400px;
      transition: 0.3s;
      object-fit: cover;
    }

    &_info {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 20px;
      width: 100%;
      box-sizing: border-box;
      transform: translateY(100%);
      background: rgb(0, 0, 0);
      transition: 0.3s;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.7399553571428572) 80%,
        rgba(0, 0, 0, 0) 100%
      );
      line-height: 20px;
      border-bottom: 4px solid $bodylight;

      &_title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px;
      }

      &_desc {
        font-size: 16px;
        margin-bottom: 20px;
      }

      &_spec {
        font-size: 16px;
        margin-bottom: 5px;
      }

      &_list {
        list-style: inside;

        li {
          margin-bottom: 5px;
        }
      }
    }

    &:hover {
      .wrapper_item_info {
        transform: translateY(0);
      }

      .wrapper_item_img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        height: 600px;
      }
    }
  }
}
