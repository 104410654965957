@import "../../styles/colors.scss";
@import "../../styles/screens.scss";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url("../../assets/images/menu-bg.png");
  background-size: cover;
  // background-color: $blue;
  border-bottom: 9px solid #b1976c;
  z-index: 1000;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  &_right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &_nav {
    display: flex;

    align-items: center;

    @media screen and (max-width: $medium) {
      display: none;
    }

    &_item {
      display: block;
      margin-left: 30px;
      font-size: 18px;
      color: $white;
      transition: 0.2s;
      font-weight: 400;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: $bodylight;
      }
    }

    &_hamburger {
      display: none;
      margin-left: 20px;
      cursor: pointer;

      @media screen and (max-width: $medium) {
        display: block;
      }
    }
  }

  &_social {
    display: flex;
    align-items: center;
    margin-left: 100px;

    @media screen and (max-width: $small) {
      margin-left: 20px;
    }

    &_item {
      margin-left: 15px;
      cursor: pointer;

      path {
        transition: 0.2s;
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        path {
          fill: $bodylight;
        }
      }
    }
  }

  &_button {
    padding: 14px 16px;
    border: none;
    border-radius: 2px;
    background-color: #b1976c;
    color: black;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    margin-left: 33px;
  }
}

.info {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  &_item {
    &_label {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &_title {
        color: #b1976c;
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 464px) {
          font-size: 16px;
        }
      }
    }

    &_value {
      color: #fff;
      // font-family: Georgia;
      font-size: 26px;
      font-weight: 700;

      @media (max-width: 464px) {
        font-size: 14px;
      }
    }
  }
}

.hamburger_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: #0a0a0a;
  display: flex;
  padding: 0 10px;
  align-items: center;
  box-sizing: border-box;

  &_nav {
    flex: 1;

    &_item {
      display: block;
      margin-bottom: 10px;
      font-size: 24px;
      color: $white;
      font-weight: 400;
      text-align: center;
      padding: 10px 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: $bodylight;
      }
    }
  }

  &_cross {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer;
  }
}
