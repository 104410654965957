@import "../../styles/colors.scss";
@import "../../styles/screens.scss";

.wrapper {
  background-color: #0a0a0a;
  padding: 50px 0;
  border-top: 9px solid #b1976c;
}

.container {
  display: flex;
  gap: 150px;

  @media screen and (max-width: $medium) {
    gap: 50px;
  }

  @media screen and (max-width: $small) {
    gap: 30px;
    flex-direction: column;
  }

  &_left {
    &_logo {
      margin-bottom: 30px;
    }

    &_button {
      margin-top: 30px;
    }

    &_contacts {
      &_item {
        display: flex;
        align-items: center;
        color: white;
        margin-bottom: 5px;
        color: #fff;
        // font-family: Georgia;
        font-size: 16px;
        font-weight: 700;

        &_icon {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          margin-right: 5px;
        }

        & a {
          display: block;
          margin: 10px 0;
          color: $lightgray;
          transition: 0.2s;

          &:hover {
            color: #b1976c;
          }
        }
      }
    }

    &_social {
      display: flex;
      gap: 10px;
      margin-top: 30px;

      &_item {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;

        path {
          transition: 0.2s;
        }

        &:hover {
          path {
            fill: #b1976c;
          }
        }
      }
    }
  }

  &_right {
    flex: 1;
  }

  &_copyright {
    padding-top: 30px;
    margin-top: 30px;
    text-align: right;
    border-top: 0.5px solid $lightgray;
    color: $lightgray;
    letter-spacing: 1px;
    font-size: 20px;
  }
}
