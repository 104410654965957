@import "../../styles/screens.scss";
@import "../../styles/colors.scss";

.wrapper {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;

  &_title {
    color: white;
  }

  &_block {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    &_item {
      flex-basis: calc(33.33% - 27px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      background-color: white;
      box-sizing: border-box;

      @media screen and (max-width: $medium) {
        flex-basis: calc(50% - 20px);
      }

      @media screen and (max-width: $small) {
        flex-basis: calc(100%);
      }

      &_icon {
        margin-bottom: 15px;
      }

      &_label {
        padding-top: 15px;
        border-top: 1px solid $blue;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: $blue;
      }
    }
  }
}
