html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 16px;
}

@font-face {
  font-family: "Times New Roman";
  src: local("Times New Roman"),
    url("./fonts/timesnewromanpsmt.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Times New Roman" !important;
  font-weight: 500;
  max-width: 100%;
  /* overflow-y: scroll; */
}

html,
body {
  overflow-x: hidden;
}

.container {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.subtitle {
  /* font-family: Georgia; */
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  color: #3b3b3b;
  /* margin-bottom: 58px; */
}

.react-multi-carousel-dot-list {
  position: relative !important;
  padding-top: 20px !important;
}

.carousel-item-padding-40-px {
  padding: 0 5px;
  box-sizing: border-box;
}

.phone-wrapper {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a68776;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
}

.phone-wrapper:hover {
  transform: rotate(10deg) scale(1.1);
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
}

.slick-slide {
  padding: 0 10px;
  box-sizing: border-box;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 1024px) {
  .container {
    width: 90%;
  }

  .phone-wrapper {
    bottom: 20px;
    right: 20px;
  }
}

@media (max-width: 464px) {
  .container {
    width: 100%;
    padding: 0 10px;
    margin: auto;
  }

  .phone-wrapper {
    bottom: 20px;
    right: 20px;
  }

  .subtitle {
    font-size: 30px;
    line-height: 36px;
  }
}
