.modal {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &_modal {
    background: white;
    overflow: auto;
    max-height: 80vh;
    position: absolute;
    padding: 20px;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border: none;
    box-sizing: border-box;

    @media (max-width: 464px) {
      width: 95vw;
      max-height: 98vh;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

    &_title {
      color: black;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }

    &_cross {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
