@import "../../styles/colors.scss";
@import "../../styles/screens.scss";

.wrapper {
  padding: 80px 0;
  background-repeat: no-repeat;
  background-size: cover;

  &_subtitle {
    margin-bottom: 58px;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    &_item {
      flex-basis: calc(33.33% - 27px);

      @media screen and (max-width: $medium) {
        flex-basis: calc(50% - 20px);
      }

      @media screen and (max-width: $small) {
        flex-basis: calc(100%);
      }
    }
  }

  &_service {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &_item {
      position: relative;
      flex-basis: calc(33.33% - 14px);
      background-color: $blue;
      height: 450px;
      color: $bodylight;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;

      @media screen and (max-width: $medium) {
        flex-basis: calc(50% - 10px);
      }

      @media screen and (max-width: $small) {
        flex-basis: calc(100%);
        height: auto;
        padding: 20px 0;
      }

      &_title {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        font-size: 26px;
        line-height: 32px;
        font-family: "DM Serif Display", serif;
        letter-spacing: 4px;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s;
      }

      &_info {
        position: absolute;
        padding: 0 25px;
        top: 50%;
        // left: 50%;
        opacity: 0;
        transform: translateY(-100px);
        transition: 0.2s;

        &_title {
          margin-bottom: 15px;
          font-size: 20px;
          line-height: 24px;
        }

        &_list {
          list-style: inside;

          & li {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      &:hover {
        .wrapper_service_item_title {
          opacity: 0;
          transform: translate(-50%, 100px);
        }

        .wrapper_service_item_info {
          opacity: 1;
          transform: translateY(-50%);
        }
      }

      @media screen and (max-width: $small) {
        .wrapper_service_item_title {
          opacity: 0;
          transform: translate(-50%, 100px);
        }

        .wrapper_service_item_info {
          position: relative;
          opacity: 1;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.callapse_item {
  background-color: $blue;
  margin-bottom: 10px;
  color: $bodylight;

  &_header {
    padding: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &_title {
      flex: 1;
      margin-right: 10px;
    }

    &_icon {
      transform: rotate(-90deg);
      transition: 0.3s;

      &_active {
        transform: rotate(0deg);
      }
    }
  }

  &_body {
    padding: 20px;
    padding-top: 10px;

    & li {
      margin-bottom: 10px;
    }
  }
}
