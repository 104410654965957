@import "../../styles/colors.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 30px;
  background-color: #b1976c;
  color: black;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &_small {
    padding: 10px 30px;
  }

  &_contact {
    padding: 14px 16px;
    border: none;
    border-radius: 2px;
    background-color: #b1976c;
    color: black;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    margin-left: 33px;
  }

  &:hover {
    background-color: darken(#b1976c, 10%);
  }
}
