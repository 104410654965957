@import "../../styles/colors.scss";
@import "../../styles/screens.scss";

.wrapper {
  padding-top: 186px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #0a0a0a;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 105% center;

  @media screen and (max-width: $small) {
    background-image: none !important;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 86px 0;
  box-sizing: border-box;

  @media screen and (max-width: $medium) {
    background-size: 60%;
    background-position: 90% 20%;
  }

  @media screen and (max-width: $small) {
    padding: 50px 10px;
    background-size: 90%;
    background-position: 90% 40%;
  }

  &_title {
    width: 65%;
    color: white;
    // font-family: Georgia;
    font-size: 32px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 28px;

    @media (max-width: 464px) {
      width: 100%;
      font-size: 26px;
      line-height: 32px;
    }
  }

  &_description {
    width: 65%;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 28px;

    @media (max-width: 464px) {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_list {
    width: 65%;
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 32px;
    font-style: normal;
    font-weight: 600;
    color: white;
    list-style-type: disc;
    list-style-position: inside;

    @media (max-width: 464px) {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_header {
    display: flex;
    align-items: center;

    @media screen and (max-width: $medium) {
      flex-direction: column;
      align-items: flex-start;
    }

    &_item {
      display: block;
      display: flex;
      align-items: center;
      margin-right: 25px;
      cursor: pointer;

      @media screen and (max-width: $medium) {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &_icon {
        margin-right: 10px;
      }

      &_label {
        color: $white;
        font-size: 14px;
        font-weight: 200;
      }
    }
  }

  &_info {
    color: $body;

    &_title {
      font-size: 112px;
      margin-bottom: 20px;
      font-weight: 700;
      letter-spacing: 8px;
      font-family: "DM Serif Display", serif;

      @media screen and (max-width: $small) {
        font-size: 66px;
        margin-bottom: 10px;
      }
    }

    &_slogan {
      font-size: 32px;
      letter-spacing: 3px;

      @media screen and (max-width: $small) {
        font-size: 22px;
        letter-spacing: 1px;
      }
    }
  }

  &_footer {
    width: 300px;

    @media screen and (max-width: $small) {
      width: 100%;
    }
  }
}
