@import "../../../styles/colors.scss";

.wrapper {
  padding: 50px 0;

  // @media (max-width: 464px) {
  // padding: 25px 0;

  // }

  &_item {
    padding: 20px;
    background-color: #212121;
    color: white;
    height: 550px;
    display: flex;
    flex-direction: column;

    @media (max-width: 464px) {
      height: 750px;
    }

    &_header {
      display: flex;
      margin-bottom: 30px;
      gap: 15px;
      margin-top: -50px;

      @media (max-width: 464px) {
        flex-direction: column;
        margin-top: -30px;
      }

      &_img {
        height: 220px;
        width: 220px;
        display: block;
        flex: 2;
        transition: 0.5s;
        object-fit: cover;
        border: 4px solid #b1976c;
        background-size: cover;
        background-position: center top;

        @media (max-width: 464px) {
          min-height: 220px;
          width: 220px;
        }
      }

      &_info {
        flex: 3;
        padding-top: 40px;

        @media (max-width: 464px) {
          padding-top: 10px;
        }

        &_name {
          color: #fff;
          // font-family: Georgia;
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 20px;

          @media (max-width: 464px) {
            font-size: 22px;
            line-height: 28px;
          }
        }

        &_description {
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;

          @media (max-width: 464px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    &_info {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 20px;
      width: 100%;
      box-sizing: border-box;
      transform: translateY(100%);
      background: rgb(0, 0, 0);
      transition: 0.5s;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.7399553571428572) 80%,
        rgba(0, 0, 0, 0) 100%
      );
      line-height: 20px;
      border-bottom: 4px solid $bodylight;

      &_title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px;
      }

      &_desc {
        font-size: 16px;
        margin-bottom: 20px;
      }

      &_spec {
        font-size: 16px;
        margin-bottom: 5px;
      }

      &_list {
        list-style: inside;

        li {
          margin-bottom: 5px;
        }
      }
    }

    &_active {
      .wrapper_item_info {
        transform: translateY(0);
      }

      .wrapper_item_img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        height: 600px;
      }
    }

    &_seperator {
      color: #fff;
      // font-family: Georgia;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 20px;
    }

    &_list {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 20px;
    }

    &_button {
      flex: 1;
      display: flex;
      align-items: flex-end;
    }
  }
}
