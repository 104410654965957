.wrapper {
  display: flex;
  flex-direction: column;
  padding: 22px;
  background-color: #f2f2f2;

  &_title {
    height: 80px;
    color: #242424;
    text-align: center;
    // font-family: Georgia;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    @media (max-width: 464px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &_list {
    height: 200px;
    list-style-type: disc;
    list-style-position: inside;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #3b3b3b;

    @media (max-width: 464px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &_button {
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
  }
}

.modal {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &_modal {
    background: white;
    overflow: auto;
    max-height: 80vh;
    position: absolute;
    padding: 20px;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border: none;
    box-sizing: border-box;

    @media (max-width: 464px) {
      width: 95vw;
      max-height: 98vh;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

    &_title {
      color: black;
      //   font-family: Georgia;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }

    &_cross {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &_list {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #3b3b3b;

    &_item {
      margin-bottom: 10px;
    }
  }
}
