.textareaContainer {
  margin-bottom: 20px;
}

.label {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
}

.textareaField {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none; /* Allow vertical resizing of the textarea */
}

.error {
  color: #ff0000;
  font-size: 14px;
  margin-top: 4px;
}
